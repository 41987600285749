import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { getAllQuiz } from '../services/home';
import { useTranslation } from 'react-i18next';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Tooltip } from 'react-tooltip'
import { getProfileImage } from 'utils/app.util';
import { useSession } from 'providers/SessionProvider';
import Spinner from 'react-bootstrap/Spinner';

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });

  return (
    <>
      {timerComponents.length ? (
        <><img src="/image/chronometer.png" className='chronometer' /> <div className='countdown-timer countdown-timer-home'> {timerComponents} </div> </>
      ) : (
        <div className='begin_the_competition'>Let's begin the competition!</div>
      )}
    </>
  );
};


const Screen2 = () => {
  const { user } = useSession();
  const [featured, setFeatured] = useState([]);
  const [popular, setPopular] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage'));
  const { t, i18n } = useTranslation();
  const [randomText, setRandomText] = useState('');
  const [competition, setCompetition] = useState([]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 5,
      slidesToSlide: 5
    }
  };

  const responsiveCompetition = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };


  useEffect(() => {
    getAllQuiz(localStorage.getItem('selectedLanguage')).then((res) => {
      const featuredArray = [];
      const popularArray = [];

      res.data.forEach((quiz) => {
        if (quiz.isFeatured) {
          featuredArray.push(quiz);
        }
        if (quiz.isPopular) {
          popularArray.push(quiz);
        }
      });
      setCompetition(res.competition)
      setFeatured(featuredArray);
      setPopular(popularArray);
    }).catch((err) => {
      console.log(err)
    })

  }, [])

  useEffect(() => {
    if (language) {
      localStorage.setItem('selectedLanguage', language);
      i18n.changeLanguage(language);
      getAllQuiz(language).then((res) => {
        const featuredArray = [];
        const popularArray = [];

        res.data.forEach((quiz) => {
          if (quiz.isFeatured) {
            featuredArray.push(quiz);
          }
          if (quiz.isPopular) {
            popularArray.push(quiz);
          }
        });

        setFeatured(featuredArray);
        setPopular(popularArray);
      }).catch((err) => {
        console.log(err)
      })
    }
  }, [language, i18n])

  useEffect(() => {
    // fetch('/json/random.json') // Path relative to the public folder
    //   .then(response => response.json())
    //   .then(data => {
    // console.log(data)
    // const texts = data.texts;
    const totalFacts = localStorage.getItem("total_facts")
    const randomIndex = Math.floor(Math.random() * (totalFacts - 1 + 1)) + 1;
    setRandomText("fun_fact_" + randomIndex);
    // })
    // .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default Screen2