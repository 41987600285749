import { DEFAULT_PROFILE_IMG } from "constants/constants";

const frequencyMap = new Map([
    ["daily", "day"],
    ["weekly", "week"],
    ["one_day", "day"],
    ["one_week", "week"]
]);

const packColorMap = new Map([
    ["1", "11, 11, 185"],
    ["2", "205, 7, 108"],
    ["3", "40, 113, 251"],
    ["4", "53, 205, 7"]
]);

const offerColorMap = new Map([
    ["Subscription", "11, 11, 185"],
    ["One Time Purchase", "205, 7, 108"]
]);

export function getProfileImage(imgSrc) {
    return imgSrc || DEFAULT_PROFILE_IMG;
}

export {
    frequencyMap,
    packColorMap,
    offerColorMap
}