export function extractNumbers(input, maxLength) {
  const str = maxLength != null ? input.slice(0, maxLength) : input;
  const matches = str.match(/\d/g);

  if (matches?.length) {
    return matches.join("");
  }

  return "";
}
